import cn from 'clsx'
import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import { MdClose, MdMoreVert } from 'react-icons/md'
import { useChat, useVisitor } from 'alpha-chat'
import Text from './Text'
import { DEVICE_TYPE } from '../utils/constant'

type ChatHeaderProps = {
  isConnected: boolean
  showCloseButton?: boolean
  onToggle?: () => void
  deviceType?: DEVICE_TYPE
}

const ChatHeader: React.FC<ChatHeaderProps> = ({
  isConnected,
  showCloseButton,
  onToggle,
  deviceType
}) => {
  const { newConversation } = useChat()
  const { business } = useVisitor()
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>()
  const ref = useRef() as MutableRefObject<HTMLDivElement>

  useEffect(() => {
    const handleClickOutside = (ev: any) => {
      if (ref.current && !ref.current.contains(ev.target)) {
        setIsMenuOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside, true)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true)
    }
  }, [])

  return (
    <div
      className={cn(
        'flex flex-row p-4 space-x-4 bg-slate-600',
        'border-b border-b-solid border-b-slate-200',
        'items-center',
        {
          'rounded-t-lg': deviceType === 'DESKTOP'
        }
      )}
    >
      {showCloseButton && (
        <div className='cursor-pointer' onClick={onToggle}>
          <MdClose className='text-slate-200' size={28} />
        </div>
      )}
      {business?.avatar ? (
        <img
          src={business?.avatar?.image_url}
          className='h-10 aspect-square rounded-full'
        />
      ) : (
        <div className='h-10 aspect-square bg-slate-200 rounded-full' />
      )}
      <div className='flex flex-col flex-1'>
        <Text className={cn('font-bold text-white')}>
          {business?.name || ' '}
        </Text>
        <div className='flex flex-row items-center space-x-2'>
          <div
            className={cn('rounded-full w-3 h-3', {
              'bg-rose-600': !isConnected,
              'bg-green-600': isConnected
            })}
          />
          <div className='text-xs text-white text-opacity-75'>
            {isConnected ? 'Connected' : 'Connecting'}
          </div>
        </div>
      </div>
      <div className='relative' ref={ref}>
        <MdMoreVert
          size={28}
          className='cursor-pointer text-slate-200'
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        />
        {isMenuOpen && (
          <div className='absolute right-0 top-10 bg-slate-200 z-50 shadow whitespace-nowrap'>
            <button
              className='p-2 text-sm lg:text-base'
              onClick={() => {
                newConversation()
                setIsMenuOpen(false)
              }}
            >
              Restart Conversation
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default ChatHeader
