import cn from 'clsx'
import moment from 'moment'
import React from 'react'
import {
  Agent,
  getDisplayName,
  isImageByFileName,
  tryParseJSON
} from 'alpha-chat'
import { MdDescription } from 'react-icons/md'
import Text from './Text'
import InputTypeOption from './ChatBot/InputTypeOption'
import InputTypeForm from './ChatBot/InputTypeForm'
import InputTypeButton from './ChatBot/InputTypeButton'

type ChatBodyProps = {
  isSelf?: boolean
  groupWithNext?: boolean
  groupWithPrevious?: boolean
  showInput?: boolean
  attachments?: Array<any>
  agent?: Agent
  events?: Array<any>
  onClick?: (text: string) => void
  // chat props
  body?: any
  sentAt?: Date
  status?: string
}

const ChatBody: React.FC<ChatBodyProps> = ({
  isSelf,
  groupWithPrevious,
  groupWithNext,
  showInput,
  attachments,
  agent,
  events,
  children,
  onClick,
  // chat props
  body,
  sentAt,
  status = 'pending'
}) => {
  const content = typeof body === 'string' ? tryParseJSON(body) : body
  const text = content ? content.text : body
  const dates = events?.filter((event) => event.type === 'date')
  const date = dates && dates.length > 0 ? dates[dates.length - 1] : undefined

  return (
    <div
      className={cn('flex flex-col space-y-1 px-2', {
        'items-end': isSelf,
        'items-start': !isSelf,
        'mb-2': !groupWithNext,
        'mb-0.5': groupWithNext
      })}
    >
      {events && events.length > 0 && (
        <div className='flex flex-col w-full mb-2 space-y-2'>
          {events
            .filter((event) => event.type !== 'date')
            .map((event) => (
              <Text
                key={event.id}
                size='none'
                className={cn('text-xs self-center text-center', {
                  block: event.type === 'event',
                  'bg-slate-200 rounded p-1': event.type !== 'event'
                })}
              >
                {event.text}
              </Text>
            ))}
          {date && (
            <Text
              key={date.id}
              size='none'
              className={cn(
                'text-xs self-center text-center',
                'bg-slate-200 rounded p-1'
              )}
            >
              {date.text}
            </Text>
          )}
        </div>
      )}
      <div
        className={cn('flex flex-col p-2', {
          'bg-emerald-200': isSelf,
          'bg-slate-200': !isSelf,
          'rounded-tr-xl rounded-tl-xl': !groupWithPrevious,
          'rounded-bl-xl': isSelf && !groupWithNext,
          'rounded-br-xl': !isSelf && !groupWithNext
        })}
        style={{ maxWidth: '75%' }}
      >
        {!groupWithPrevious && (
          <Text size='sm' className='font-bold'>
            {isSelf ? 'You' : getDisplayName(agent, 'Agent')}
          </Text>
        )}
        {(text || children) && (
          <Text size='sm' className='break-words'>
            {text || children}
          </Text>
        )}

        {attachments && attachments.length > 0 && (
          <div
            className={cn('grid grid-cols-2 gap-2 mt-2', {
              'grid-cols-1': attachments.length === 1,
              'grid-cols-2': attachments.length > 1
            })}
          >
            {attachments.map((attachment, i) => {
              if (isImageByFileName(attachment.file_name))
                return (
                  <img
                    key={i}
                    src={attachment.image_url}
                    className='cursor-pointer'
                    onClick={() => window.open(attachment.image_url)}
                  />
                )

              return (
                <Text
                  key={i}
                  size='none'
                  className={cn(
                    'text-sm cursor-pointer flex items-center p-1 hover:bg-slate-300',
                    'border border-dashed border-slate-400'
                  )}
                  onClick={() => window.open(attachment.image_url)}
                >
                  <MdDescription className='mr-1' size={16} />
                  {attachment.file_name}
                </Text>
              )
            })}
          </div>
        )}
      </div>

      {!groupWithNext && (
        <Text size='none' className='text-xs'>
          {sentAt && moment(sentAt).format('HH:mm')}
          {isSelf && sentAt && ' . '}
          {isSelf && `${status}`}
        </Text>
      )}

      {showInput && (
        <>
          <Text size='sm' className='break-words'>
            {content?.helper_text}
          </Text>
          {content?.input_type === 'button' && (
            <div className={cn('flex flex-wrap')}>
              <InputTypeButton options={content?.options} onClick={onClick} />
            </div>
          )}
          {content?.input_type === 'form' && (
            <InputTypeForm fields={content?.form_fields} onClick={onClick} />
          )}
          {content?.input_type === 'option' && (
            <InputTypeOption options={content?.options} onClick={onClick} />
          )}
        </>
      )}
    </div>
  )
}

export default ChatBody
