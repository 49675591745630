import cn from 'clsx'
import { FC } from 'react'

const LoadingDots: FC = () => {
  return (
    <span
      className={cn('inline-flex text-center items-center h-5')}
    >
      <span
        className={cn(
          'animate-blink mx-0.5 rounded-full h-2 w-2 bg-current'
        )}
        key={`dot_1`}
      />
      <span
        className={cn(
          'animate-blink mx-0.5 rounded-full h-2 w-2 bg-current'
        )}
        key={`dot_2`}
        style={{ animationDelay: '0.2s' }}
      />
      <span
        className={cn(
          'animate-blink mx-0.5 rounded-full h-2 w-2 bg-current'
        )}
        key={`dot_3`}
        style={{ animationDelay: '0.4s' }}
      />
    </span>
  )
}

export default LoadingDots
