import cn from 'clsx'
import { FC, useRef } from 'react'
import { MdAttachFile, MdSend } from 'react-icons/md'

export type ChatActionBarProps = {
  value: string
  onChangeValue?: (value: string) => void
  onChangeAttachment?: (files: FileList | null) => void
  onEnter?: () => void
  canSendMessage?: boolean
  disabled?: boolean
}

const ChatActionBar: FC<ChatActionBarProps> = ({
  value,
  onChangeValue,
  onChangeAttachment,
  onEnter,
  canSendMessage,
  disabled
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <div className='flex flex-row w-full items-center'>
      <input
        className={cn(
          'w-full text-sm outline-none mr-2',
          'p-2 bg-slate-100 disabled:bg-slate-50 relative rounded'
        )}
        placeholder='Ketik Pesan Anda'
        ref={inputRef}
        value={value}
        onChange={(e) => onChangeValue && onChangeValue(e.target.value)}
        disabled={disabled}
        onKeyUp={(ev) => {
          ev.preventDefault()
          if (ev.key === 'Enter') {
            onEnter && onEnter()
          }
        }}
      />
      <button
        className={cn(
          'relative rounded overflow-hidden bg-slate-100 disabled:bg-slate-50'
        )}
        style={{ padding: 18 }}
        disabled={disabled}
      >
        <MdAttachFile
          size={20}
          className={cn('absolute left-2 top-2', {
            'text-slate-400': disabled,
            'text-slate-600': !disabled
          })}
        />
        <input
          type='file'
          multiple
          className={cn('absolute top-0 bottom-0 right-0 opacity-0', {
            'cursor-pointer': !disabled
          })}
          onChange={(e) =>
            onChangeAttachment && onChangeAttachment(e.target.files)
          }
          disabled={disabled}
        />
      </button>
      <button
        className={cn('bg-slate-600 disabled:bg-slate-400 rounded p-2 ml-2', {
          'cursor-pointer': !disabled
        })}
        disabled={!canSendMessage}
        onClick={(ev) => {
          ev.preventDefault()
          inputRef?.current?.focus()
          onEnter && onEnter()
        }}
      >
        <MdSend size={20} className='text-white' />
      </button>
    </div>
  )
}

export default ChatActionBar
