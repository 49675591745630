import cn from 'clsx'
import Text from './Text'
import React, { InputHTMLAttributes } from 'react'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  containerClassName?: string
  onChange?: (...args: any[]) => any
  validation?: 'email'
}

const Input: React.FC<InputProps> = ({
  className,
  containerClassName,
  children,
  onChange,
  type,
  validation,
  ...rest
}) => {
  let placeholder = rest.placeholder

  if (!placeholder && typeof children === 'string') {
    placeholder = children
  }

  if (validation === 'email') {
    type = 'email'
  }

  return (
    <div className={cn('space-y-1', containerClassName)}>
      <Text className='font-bold' size='sm'>
        {children}
        {rest.required && <span className='text-rose-600'>*</span>}
      </Text>
      <input
        className={cn(
          'appearance-none transition duration-150 ease-in-out',
          'border border-black border-opacity-25 rounded-lg',
          'focus:outline-none focus:shadow-outline-normal',
          'placeholder:text-slate-400',
          'bg-primary p-2 w-full text-black',
          'text-xs sm:text-sm md:text-sm lg:text-base',
          className
        )}
        onChange={(e) => onChange && onChange(e.target.value)}
        autoComplete='off'
        autoCorrect='off'
        autoCapitalize='off'
        spellCheck='false'
        placeholder={placeholder}
        type={type}
        {...rest}
      />
    </div>
  )
}

export default Input
