import cn from 'clsx'
import { FC } from 'react'

const LoadingSpinner: FC = () => {
  return (
    <div
      className={cn(
        'border-4 border-slate-600 border-r-transparent border-solid inline-block',
        'animate-spin rounded-full w-8 h-8'
      )}
      style={{
        verticalAlign: '-0.125em'
      }}
    />
  )
}

export default LoadingSpinner
