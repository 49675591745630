import cn from 'clsx'
import { FC, HTMLProps } from 'react'
import { MdClose, MdOutlineChat } from 'react-icons/md'
import LoadingDots from './LoadingDots'

type WidgetButtonProps = HTMLProps<HTMLDivElement> & {
  isOpen?: boolean
  loading?: boolean
  newMessageCount: number
}

const WidgetButton: FC<WidgetButtonProps> = ({
  onClick,
  isOpen,
  newMessageCount,
  loading,
  className
}) => {
  return (
    <div
      className={cn(
        'select-none',
        'flex flex-shrink-0 rounded-full justify-center items-center',
        'bg-slate-600 text-white cursor-pointer',
        'w-16 h-16 relative ml-auto',
        'justify-self-end',
        className
      )}
      onClick={onClick}
    >
      {!isOpen && newMessageCount > 0 && (
        <div
          className={cn(
            'absolute top-1.5 right-1.5 text-white bg-rose-600 rounded-full text-sm shadow-lg',
            'h-4 aspect-square w-auto flex items-center justify-center p-1'
          )}
        >
          {newMessageCount}
        </div>
      )}
      {loading ? (
        <LoadingDots />
      ) : isOpen ? (
        <MdClose className='w-1/2 h-1/2' />
      ) : (
        <MdOutlineChat className='w-1/2 h-1/2' />
      )}
    </div>
  )
}

export default WidgetButton
