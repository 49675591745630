import cn from 'clsx'
import { CSSProperties, FC } from 'react'

const URL_REGEX =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/

const LINE_BREAK_REGEX = /(?:\r\n|\r|\n)/g

const BIUConverter = (text: string, prevSymbols?: string[]): any[] => {
  let modifiedText: any = text
  const symbols: string[] = prevSymbols || []

  if (
    modifiedText[0] === '*' &&
    modifiedText[modifiedText.length - 1] === '*'
  ) {
    modifiedText = modifiedText.substring(1, text.length - 1)
    symbols.push('b')
  }

  if (
    modifiedText[0] === '~' &&
    modifiedText[modifiedText.length - 1] === '~'
  ) {
    modifiedText = text.substring(1, modifiedText.length - 1)
    symbols.push('s')
  }

  if (
    modifiedText[0] === '_' &&
    modifiedText[modifiedText.length - 1] === '_'
  ) {
    modifiedText = text.substring(1, modifiedText.length - 1)
    symbols.push('i')
  }

  if (modifiedText !== text) return BIUConverter(modifiedText, symbols)

  symbols.forEach((symbol, i) => {
    if (symbol === 'b') modifiedText = <b key={i}>{modifiedText}</b>
    if (symbol === 'i') modifiedText = <i key={i}>{modifiedText}</i>
    if (symbol === 's') modifiedText = <s key={i}>{modifiedText}</s>
  })

  return modifiedText
}

export type TextProps = {
  children?: React.ReactNode | any
  className?: string
  onClick?: () => any
  style?: CSSProperties
  accent?: boolean
  size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl' | 'none'
}

const Text: FC<TextProps> = ({
  children,
  className,
  onClick,
  accent,
  size = 'default',
  ...rest
}) => {
  const renderText = (text: string) =>
    text.split(' ').map((part) => {
      const key = Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '')
        .substring(0, 5)

      if (URL_REGEX.test(part))
        return (
          <a
            key={key}
            href={part}
            target='_blank'
            rel='noreferrer'
            className='underline'
          >
            {`${part} `}
          </a>
        )

      if (LINE_BREAK_REGEX.test(part)) {
        return part.split('\n').reduce((res, item, i): any => {
          if (!res) {
            return [BIUConverter(item)]
          }

          return [...res, <br key={key + i} />, BIUConverter(item), ' ']
        })
      }

      return [BIUConverter(part), ' ']
    })

  return (
    <div
      className={cn(
        {
          'text-2xs sm:text-2xs md:text-xs lg:text-xs': size === 'xs',
          'text-xs sm:text-xs md:text-sm lg:text-sm': size === 'sm',
          'text-sm sm:text-sm md:text-base lg:text-base': size === 'base',
          'text-base sm:text-base md:text-lg lg:text-lg': size === 'lg',
          'text-lg sm:text-lg md:text-xl lg:text-xl': size === 'xl',
          'text-accent': accent,
          'cursor-pointer': Boolean(onClick)
        },
        className
      )}
      onClick={onClick}
      {...rest}
    >
      {typeof children === 'string' ? renderText(children) : children}
    </div>
  )
}

export default Text
